import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min'; // Bootstrap JS bundle (includes Popper.js)

import ReactDOM from 'react-dom/client';
import App from './App';

const root=ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div>
     <App />
     <link
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
  rel="stylesheet"
/>

  </div>
   
);


import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom"; // Import useNavigate
import './css/popuppage.css';

export default function Popuppage({ togglePopup, data }) {
  const popupRef = useRef(null);
  const navigate = useNavigate(); // Initialize navigate

  const classes = [
    [
      { type: "Weekday", Day: 'Mon-Fri', time: 'Flexible Timing', duration: '90 Mins/Session', fee: 'INR 15000 /-' },
      { type: "Weekend", Day: 'Sat-Sun', time: 'Flexible Timing', duration: '3 Hours/Session', fee: 'INR 17000 /-' },
      { type: "Intensive", Day: 'Mon-Fri', time: 'Flexible Timing', duration: '3 Hours/Session', fee: 'INR 17000 /-' }
    ],
    [
      { type: "Weekday", Day: 'Mon-Fri', time: 'Flexible Timing', duration: '90 Mins/Session', fee: 'INR 18000 /-' },
      { type: "Weekend", Day: 'Sat-Sun', time: 'Flexible Timing', duration: '3 Hours/Session', fee: 'INR 19500 /-' },
      { type: "Intensive", Day: 'Mon-Fri', time: 'Flexible Timing', duration: '3 Hours/Session', fee: 'INR 19500 /-' }
    ],
    [
      { type: "Weekday", Day: 'Mon-Fri', time: 'Flexible Timing', duration: '90 Mins/Session', fee: 'INR 20000 /-' },
      { type: "Weekend", Day: 'Sat-Sun', time: 'Flexible Timing', duration: '3 Hours/Session', fee: 'INR 21500 /-' },
      { type: "Intensive", Day: 'Mon-Fri', time: 'Flexible Timing', duration: '3 Hours/Session', fee: 'INR 23000 /-' }
    ],
    [
      { type: "Weekday", Day: 'Mon-Fri', time: 'Flexible Timing', duration: '90 Mins/Session', fee: 'INR 25000 /-' },
      { type: "Weekend", Day: 'Sat-Sun', time: 'Flexible Timing', duration: '3 Hours/Session', fee: 'INR 26500 /-' },
      { type: "Intensive", Day: 'Mon-Fri', time: 'Flexible Timing', duration: '3 Hours/Session', fee: 'INR 27500 /-' }
    ],
    [
      { type: "Weekday", Day: 'Mon-Fri', time: 'Flexible Timing', duration: '3 Hours/Session', fee: 'INR 30000 /-' }
    ],
    [
      { type: "Weekday", Day: 'Mon-Fri', time: 'Flexible Timing', duration: '3 Hours/Session', fee: 'INR 35000 /-' }
    ],
    [
      { type: "A1", Day: 'Mon-Fri', time: 'Flexible Timing', duration: '15 Hours', fee: 'INR 5000 /-' },
      { type: "A2", Day: 'Mon-Fri', time: 'Flexible Timing', duration: '15 Hours', fee: 'INR 5000 /-' },
      { type: "B1", Day: 'Mon-Fri', time: 'Flexible Timing', duration: '20 Hours', fee: 'INR 10000 /-' },
      { type: "B2",Day: 'Mon-Fri', time: 'Flexible Timing', duration: '20 Hours', fee: 'INR 14000 /-' }

    ],
    [
      { type: "A1",Day: 'Mon-Fri', time: 'Flexible Timing', duration: '20 Hours', fee: 'INR 8000 /-' },
      { type: "A2",Day: 'Mon-Fri', time: 'Flexible Timing', duration: '20 Hours', fee: 'INR 8000 /-' },
      { type: "B1",Day: 'Mon-Fri', time: 'Flexible Timing', duration: '30 Hours', fee: 'INR 12000 /-' },
      { type: "B2",Day: 'Mon-Fri', time: 'Flexible Timing', duration: '40 Hours', fee: 'INR 16000 /-' }

    ]
  ];

  const batchIndex = () => {
    switch (data.level) {
      case 'A1': return 0;
      case 'A2': return 1;
      case 'B1': return 2;
      case 'B2': return 3;
      case 'C1': return 4;
      case 'C2': return 5;
      case '\u00A0G\u00A0' : return 6;
      case '\u00A0E\u00A0' : return 7;
      default: return 0;
    }
  };

  const batch = batchIndex();

  // Close popup on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        togglePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [togglePopup]);

  // Handle Enrollment
  const handleEnroll = () => {
    togglePopup(); // Close the popup before navigating
    navigate('/register'); // Navigate to the register page
  };

  return (
    <div className="popup show">
      <div className="popup-content" ref={popupRef}>
        <span className="close" onClick={togglePopup}>&times;</span>
        <h2>German {data.level==='\u00A0G\u00A0'||'\u00A0E\u00A0'?data.title:data.level} Batches</h2>
        <h5>Register now!! Only limited seats available</h5>
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-12">
              {classes[batch].map((batchDetail, index) => (
                <div key={index} className="batchDetail">
                  <div className="courseLevel d-flex justify-content-between">
                    <strong>{data.level==='\u00A0G\u00A0'||'\u00A0E\u00A0'?data.title:data.level} {batchDetail.type} Batch</strong>
                    <strong>Price: {batchDetail.fee}</strong>
                  </div>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td>{batchDetail.duration}</td>
                        <td>{batchDetail.Day}</td>
                        <td>{batchDetail.time}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-end">
                    {/* Button to enroll */}
                    <button className="btn btn-primary" onClick={handleEnroll}>Enroll</button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "../components/ausbildung.css"
import nursepic from "./nursepic.webp";


const PflegekursPage = () => {
  const [isDownloading,setIsDownloading]=React.useState(false)
  const [isSubmitting,setIsSubmitting]=React.useState(false)

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    resume: null
  });
  

function handleDownload(){
  setIsDownloading(true)

  setTimeout(()=>{
    setIsDownloading(false)
  },5000)
}



const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
};

// Handle file input change and validate file size
const handleFileChange = (e) => {
  const file = e.target.files[0];
  // Check file size
  if (file && file.size > 5 * 1024 * 1024) {
    alert("File size must be less than 5MB");
    e.target.value = ""; // Clear the file input
  } else {
    setFormData({ ...formData, resume: file });
  }
};

// Handle form submission
const handleSubmit = async (e) => {
  e.preventDefault();

  // Check if all fields are filled
  if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone || !formData.resume) {
    alert("Please fill in all required fields.");
    return;
  }
  else{
    setIsSubmitting(true)
  }


  // Create a FormData object for file upload
  const data = new FormData();
  data.append("firstName", formData.firstName);
  data.append("lastName", formData.lastName);
  data.append("email", formData.email);
  data.append("phone", formData.phone);
  data.append("resume", formData.resume);

  try {
    // Send form data to the backend
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/consultation`, {
      method: "POST",
      body: data,
    });
    if (response.ok) {
      setIsSubmitting(false)
      // If the response is OK (status 2xx), show success message
      alert("Consultation booked successfully!");
      
      // Reset the form after successful submission
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        resume: null,
      });
    } else {
      // Handle cases where response is not OK
      const contentType = response.headers.get("Content-Type");
    
      if (contentType && contentType.includes("application/json")) {
        // If the response is JSON, parse it and show the error message
        const error = await response.json();
        alert("Error: " + (error.error || "Unknown error"));
      } else {
        // If it's not JSON (likely an HTML error page), show a generic error
        const errorText = await response.text(); // Get the error page text
        console.error("Unexpected error response:", errorText);
        alert("Something went wrong. Please try again.");
      }
    }
    
  } catch (error) {
    console.error("Error submitting form:", error);
    alert("Failed to book consultation. Please try again later.");
  }
};


  return (
    <div className="containerPflege my-5">
      <Helmet>
      <title>Pflegekurs - German Healthcare Certification for Indian Nurses</title>
      <meta
        name="description"
        content="Join our Pflegekurs and unlock career opportunities in Germany's healthcare system. Comprehensive curriculum, language training, internships, and cultural adaptation for Indian nurses."
      />
      <meta
        name="keywords"
        content="Pflegekurs, German healthcare certification, nursing in Germany, Indian nurses in Germany, nursing career abroad, healthcare training Germany, German language for nurses, visa support for nurses, clinical internships Germany"
      />
      <meta name="author" content="Your Organization Name" />
      <meta
        property="og:title"
        content="Pflegekurs - German Healthcare Certification for Indian Nurses"
      />
      <meta
        property="og:description"
        content="Elevate your nursing career with our Pflegekurs. Tailored for Indian nurses, we offer training to meet German healthcare standards, language skills, and cultural adaptation."
      />
      <meta property="og:image" content="URL_to_nursepic.webp" />
      <meta
        property="og:url"
        content="YourPageURL" // Replace with actual page URL
      />
      <meta property="og:type" content="website" />
    </Helmet>

      {/* Hero Section */}
      <header className="text-center mb-5">
        <h1 className="display-4 fw-bold text-primary PflegeH1" >German Healthcare Certification for Indian Nurses</h1>
        <p className="text-muted fs-5 PflegePara">
          Elevate your nursing career with a specialized Pflegekurs designed to integrate Indian nurses into Germany's healthcare system.
        </p>
        <img
          src={nursepic}
          alt="Nurses in a German healthcare setting"
          className="img-fluid mt-4 rounded shadow nursePic"
        />
      </header>

      {/* Key Points */}
      <section className="key-benefits mb-5">
        <h2 className="text-center text-secondary mb-4 PflegeH2">Key Benefits of Our Program</h2>
        <div className="row g-3">
          <div className="col-md-6">
            <div className="p-3 rounded bg-light shadow-sm h-100">
              <h5 className="text-primary PflegeH5">Comprehensive Curriculum</h5>
              <p className="PflegePara">Prepare for success with modules tailored to German healthcare standards.</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="p-3 rounded bg-light shadow-sm h-100">
              <h5 className="text-primary PflegeH5">Career Opportunities</h5>
              <p className="PflegePara">Unlock lucrative nursing positions across hospitals and clinics in Germany.</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="p-3 rounded bg-light shadow-sm h-100">
              <h5 className="text-primary PflegeH5">Hands-on Training</h5>
              <p>Gain real-world experience with clinical rotations and internships.</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="p-3 rounded bg-light shadow-sm h-100">
              <h5 className="text-primary PflegeH5">Visa & Accommodation Support</h5>
              <p className="PflegePara">Receive expert guidance on visa processes and housing options.</p>
            </div>
          </div>
        </div>
      </section>

      {/* About the Course */}
      <section className="course-overview mb-5">
        <h2 className="text-center text-secondary mb-4 PflegeH2">Course Overview</h2>
        <p className="text-justify PflegePara">
          Our Pflegekurs is designed to equip Indian nurses with the skills, knowledge, and cultural understanding necessary for a successful career in Germany’s healthcare system. The course not only provides professional training but also ensures a smooth transition to working and living in Germany.
        </p>
        <div className="row g-3">
          <div className="col-md-4">
            <div className="p-3 rounded bg-light shadow-sm h-100 pflegeCourse">
              <h5 className="text-primary PflegeH5">Comprehensive Nursing Modules</h5>
              <p className="PflegePara">
                Gain in-depth knowledge of medical protocols, terminology, and best practices to meet German healthcare standards.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="p-3 rounded bg-light shadow-sm h-100 pflegeCourse">
              <h5 className="text-primary PflegeH5">Language Training</h5>
              <p>Master German up to the B2 level with expert language coaches.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="p-3 rounded bg-light shadow-sm h-100 pflegeCourse">
              <h5 className="text-primary PflegeH5">Clinical Internships</h5>
              <p className="PflegePara">Gain practical knowledge in German healthcare settings.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="p-3 rounded bg-light shadow-sm h-100 pflegeCourse">
              <h5 className="text-primary PflegeH5">Cultural Adaptation</h5>
              <p className="PflegePara">Learn how to adapt to professional and cultural norms in Germany.</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="p-3 rounded bg-light shadow-sm h-100 pflegeCourse">
              <h5 className="text-primary PflegeH5">One-on-One Sessions with German Doctors</h5>
              <p className="PflegePara">
                Learn directly from experienced German doctors who will mentor and guide you in understanding complex medical cases and workflows.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="p-3 rounded bg-light shadow-sm h-100 pflegeCourse">
              <h5 className="text-primary PflegeH5">Speaking Sessions with Native Germans</h5>
              <p className="PflegePara">
                Improve your German communication skills through interactive speaking sessions with native speakers, focusing on professional and everyday scenarios.
              </p>
            </div>
          </div>
        </div>
      </section>
     {/* Job Opportunities */}
<section className="mb-5">
  <h2 className="text-center text-secondary mb-4 PflegeH2">Career Opportunities</h2>
  <div className="row g-3">
    <div className="col-md-6 col-lg-3">
      <div className="p-3 rounded bg-light shadow-sm h-100 text-center">
        <i className="fas fa-hospital fa-2x text-primary mb-3"></i>
        <h5 className="text-primary PflegeH5">Hospitals and Clinics</h5>
        <p className="PflegePara">Work in renowned hospitals and medical institutions.</p>
      </div>
    </div>
    <div className="col-md-6 col-lg-3">
      <div className="p-3 rounded bg-light shadow-sm h-100 text-center">
        <i className="fas fa-wheelchair fa-2x text-primary mb-3"></i>
        <h5 className="text-primary PflegeH5">Rehabilitation Centers</h5>
        <p className="PflegePara">Help patients regain their health and independence.</p>
      </div>
    </div>
    <div className="col-md-6 col-lg-3">
      <div className="p-3 rounded bg-light shadow-sm h-100 text-center">
        <i className="fas fa-hand-holding-heart fa-2x text-primary mb-3"></i>
        <h5 className="text-primary PflegeH5">Long-Term Care Facilities</h5>
        <p className="PflegePara">Provide dedicated care to elderly and chronic patients.</p>
      </div>
    </div>
    <div className="col-md-6 col-lg-3">
      <div className="p-3 rounded bg-light shadow-sm h-100 text-center">
        <i className="fas fa-home fa-2x text-primary mb-3"></i>
        <h5 className="text-primary PflegeH5">Community Healthcare Services</h5>
        <p className="PflegePara">Deliver healthcare directly to the community.</p>
      </div>
    </div>
  </div>
</section>

      <div style={{display:'flex',justifyContent:'center',marginBottom:'1em'}}>
      <NavLink className="Pflegebrochure" style={{ margin: '0 auto' }} onClick={handleDownload} disabled={isDownloading} to='https://drive.google.com/uc?export=download&id=1B1uRk_Sd-dJxmMnSXKzC46_FP4MMCk_u
'>{isDownloading? "Downloading": "Dowload Brochure"}</NavLink>
      </div>
        
      {/* Book Consultation */}
      <section className="mb-5">
        <h2 className="text-center text-secondary mb-4 PflegeH2">Book a Consultation Session</h2>
        <form className="bg-light p-5 rounded shadow" onSubmit={handleSubmit}>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="firstName" className="PflegeFormLabel form-label">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className="PflegeFormControl form-control"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="lastName" className="PflegeFormLabel form-label">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                className="PflegeFormControl form-control"
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="email" className="PflegeFormLabel form-label">Email Address</label>
              <input
                type="email"
                id="email"
                name="email"
                className="PflegeFormControl form-control"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-12 col-md-6">
              <label htmlFor="phone" className="PflegeFormLabel form-label">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                className="PflegeFormControl form-control"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="resume" className="PflegeFormLabel form-label">Upload Resume (PDF/Word)</label>
            <input
              type="file"
              id="resume"
              className="PflegeFormControl form-control"
              accept=".pdf,.doc,.docx"
              onChange={handleFileChange}
              required
            />
          </div>
          <div className="text-center">
            <button type="submit" className="PflegeButton btn btn-success btn-lg" disabled={isSubmitting} onclick={handleSubmit}>{isSubmitting?'Submitting...':'Submit'}</button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default PflegekursPage;

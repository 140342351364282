import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaw, faStar, faBook, faUsers, faGraduationCap, faChalkboardTeacher, faLaptop, faMobileAlt, faGlobe } from '@fortawesome/free-solid-svg-icons';
import student from "./online.webp"
import certificateImage from './courseCertificate.webp';
import pup1 from "./pup1.webp";
import pup2 from "./pup2.webp";
import "./css/homecontent.css";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import Testimonials from './Testimonials';

export default function Homecontent() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1200, // Animation duration in milliseconds
      easing: 'ease-in-out', // Easing function
      once: false, // Animates elements only once
    });
  }, []);

  // Function to toggle the modal
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="Homecontent">
      <Helmet>
        <title>No. 1 German Language School in India | Learn German with Experts</title>
        <meta name="description" content="Join the best German language school in India. Our expert instructors offer comprehensive courses for all levels. Enroll today to start your journey to fluency!" />
        <meta name="keywords" content="German language school, best German classes in India, No. 1 German language school, learn German, German courses, German fluency, German for beginners, advanced German, German language institute, online German courses" />
        <link rel="canonical" href="https://www.ourgermanschool.de/" />
      </Helmet>
      <div className="containerHomeFirstSection d-flex flex-column flex-md-row justify-content-between mt-4">
        <div className="HomecontentDescription d-flex flex-column align-items-center text-center text-md-start" data-aos="fade-up">
          <h3 className="typewriter">Our German School</h3>
          <h5 data-aos="fade-up" data-aos-delay="200">A one-stop destination for all your German language needs!</h5>
          <ul className="list-unstyled mt-3" data-aos="fade-up" data-aos-delay="400">
            <li className="mb-2">★ Interactive Group Classes</li>
            <li className="mb-2">★ Tailored Curriculum</li>
            <li className="mb-2">★ Intensive Exam Preparation</li>
            <li className="mb-2">★ Become part of the community</li>
          </ul>
          <NavLink className="btn enroll-btn mt-3" to="/register" data-aos="fade-up" data-aos-delay="600">Enroll Now</NavLink>
        </div>
        <div className="pictures mt-4" data-aos="fade-left">
          <img src={student} className="img-fluid studentImg rounded" id="frontimage" alt="Student" />
        </div>
      </div>

      <div className="courseList">
        <h3 className="welcomeNote">Welcome to Our German School, where mastering the German language is just a click away! Whether you're a beginner or looking to perfect your fluency, we offer courses tailored to your needs.</h3>
        <div className="row justify-content-center" data-aos="fade-up">
          {renderCourseCard("A1", ["Understand and use familiar everyday expressions.", "Introduce oneself and others.", "Interact in a simple way."])}
          {renderCourseCard("A2", ["Understand main ideas of complex text.", "Interact with fluency and spontaneity.", "Produce clear, detailed text."])}
          {renderCourseCard("B1", ["Understand sentences and frequently used expressions.", "Communicate in simple and routine tasks.", "Describe in simple terms."])}
          {renderCourseCard("B2", ["Understand a wide range of demanding texts.", "Express ideas fluently and spontaneously.", "Use language flexibly and effectively."])}
          {renderCourseCard("C1", ["Understand main points of clear standard input.", "Deal with most situations while travelling.", "Produce simple connected text."])}
          {renderCourseCard("C2", ["Understand with ease virtually everything heard or read.", "Summarize information from different sources.", "Express oneself spontaneously, very fluently and precisely."])}
        </div>
      </div>

      <div className="text-center mt-5 mb-5" data-aos="zoom-in">
        <h4 className="chooseOurGerman">Why choose OurGermanSchool?</h4>
      </div>

      <div className="carousel-container mt-5 mb-5">
        <div id="customCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row g-3 justify-content-center">
                {renderCarouselItem(faStar, "Interactive Group Classes", "Join interactive group classes and improve your German speaking skills.")}
                {renderCarouselItem(faBook, "Tailored Curriculum", "Our curriculum is tailored to meet individual student needs.")}
                {renderCarouselItem(faUsers, "Community", "Become part of a supportive community of learners.")}
                {renderCarouselItem(faGraduationCap, "Exam Preparation", "Get ready for your exams with our intensive preparation courses.")}
              </div>
            </div>
            <div className="carousel-item">
              <div className="row g-3 justify-content-center">
                {renderCarouselItem(faChalkboardTeacher, "Experienced Teachers", "Learn from experienced and certified teachers.")}
                {renderCarouselItem(faLaptop, "Online Classes", "Access our courses online from the comfort of your home.")}
                {renderCarouselItem(faMobileAlt, "Mobile Friendly", "Our platform is mobile-friendly for learning on the go.")}
                {renderCarouselItem(faGlobe, "Global Reach", "Join students from all over the world.")}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="testimonialsHeading"><h3>Student Journeys: In their own words</h3></div>
      <Testimonials/>
      <div className="courseCertificate p-5 border rounded" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <h4 className="elevateHeading">Elevate Your Career</h4>
              <p className="courseCertificateDesc">
                Upon successful completion of your language course, you will be awarded a certificate from OurGermanSchool. This credential will significantly enhance your professional profile and elevate your resume.
              </p>
              <NavLink className="btn" style={{ backgroundColor: '#6fa06f', width: '8em' }} to='register'>Join Course</NavLink>
            </div>
            <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
              <img src={certificateImage} alt="Certificate" className="img-fluid rounded" />
            </div>
          </div>
        </div>
      </div>

      {/* Floating Puppy Icon */}
      <div className="floating-puppy" onClick={toggleModal}>
        <FontAwesomeIcon icon={faPaw} size="2x" />
      </div>

      {/* Modal Popup */}
      {showModal && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-modal" onClick={toggleModal}>&times;</span>
            <div className="puppy" style={{ display: 'flex', flexDirection: 'column' }}>
              <img src={pup1} className="pup1" alt="pup1" />
              <img src={pup2} className="pup2" alt="pup2" />
            </div>
            <h4>A Portion of Tuition Fees Goes to Animal Shelters!</h4>
            <p>
              At Our German School, we believe in giving back. That’s why we donate a part of our tuition fees to animal shelters to help animals in need.
              Every time you enroll, you’re contributing to a great cause.
            </p>
          </div>
        </div>
      )}
    </div>
  );

  function renderCourseCard(title, points) {
    return (
      <div className="courseCards col-6 col-md-3 col-lg-4 mb-4" data-aos="fade-up">
        <NavLink to="/courses" className="card-link">
          <div className="card course-card">
            <div className="card-body">
              <h5 className="card-title german-flag">{title}</h5>
              <ul className="card-text">
                {points.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          </div>
        </NavLink>
      </div>
    );
  }

  function renderCarouselItem(icon, heading, description) {
    return (
      <div className="col-md-3 text-center">
        <div className="p-3 bg-white rounded shadow-sm h-100">
          <FontAwesomeIcon icon={icon} size="2x" className="mb-3" />
          <h5>{heading}</h5>
          <p>{description}</p>
        </div>
      </div>
    );
  }
}

import React, { useState, useMemo } from "react";
import Select from "react-select";
import CountryList from "react-select-country-list";
import "./css/registerpage.css"; 
import {Helmet} from 'react-helmet';

export default function Registerpage() {
  const [selectOption, setSelectOption] = useState('');
  const [countryValue, setCountryValue] = useState(null);
  const [activeButton, setActiveButton] = useState('personal');
  const [showThankYou, setShowThankYou] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // State variables for inputs
  const [firstName, setFirstName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [lastName, setLastName] = useState('');
  const [address1, setAddress1]= useState('');
  const [address2, setAddress2]= useState('');
  const [email, setEmail] = useState('');
  const [declared, setDeclared] = useState(false);
  const [terms, setTerms] = useState(false);
  const [course, setCourse] = useState('');
  const [courseType, setCourseType] = useState('');
  const [courseTime, setCourseTime] = useState('');
  const [city,setCity]= useState('');
  const [pincode,setPincode]=useState('');

  // State variables for input validation
  const [isSelectOption,setIsSelectOption]=useState(true)
  const [isFirstName, setIsFirstName] = useState(true);
  const [isLastName, setIsLastName] = useState(true);
  const [isMobileNumber,setIsMobileNumber]=useState(true);
  const [isEmail, setIsEmail] = useState(true);
  const [isDeclared, setIsDeclared] = useState(true);
  const [isTerms, setIsTerms] = useState(true);
  const [isCourse, setIsCourse] = useState(true);
  const [isCourseType, setIsCourseType] = useState(true);
  const [isCourseTime, setIsCourseTime] = useState(true);
  const [isAddress1, setIsAddress1] = useState(true);
  const [isAddress2, setIsAddress2] = useState(true);
  const [isCity,setIsCity] =useState(true);
  const [isPincode,setIsPincode]=useState(true)

  const countryOptions = useMemo(() => CountryList().getData(), []);

  function validatePersonalDetails() {
    const firstNameValid = firstName.trim() !== '';
    const lastNameValid = lastName.trim() !== '';
    const mobileNumberValid=mobileNumber
    const emailValid = email.includes('@');
    const declaredValid = declared;
    const termsValid = terms;
    const selectOptionValid = selectOption !== '';
    const address1Valid=address1.trim()!== '';
    const address2Valid=address2.trim()!== '';
    const cityValid=city.trim()!=='';
    const pincodeValid =/^\d+$/.test(pincode.trim());
    setIsFirstName(firstNameValid);
    setIsLastName(lastNameValid);
    setIsEmail(emailValid);
    setIsDeclared(declaredValid);
    setIsTerms(termsValid);
    setIsMobileNumber(mobileNumberValid)
    setIsSelectOption(selectOptionValid)
    setIsAddress1(address1Valid)
    setIsAddress2(address2Valid)
    setIsCity(cityValid)
    setIsPincode(pincodeValid)


    return firstNameValid && lastNameValid && emailValid && declaredValid && termsValid &&selectOptionValid &&address1Valid && cityValid &&pincodeValid;
  }

  function validateCourseDetails() {
    const courseValid = course !== '';
    const courseTypeValid = courseType !== '';
    const courseTimeValid = courseTime !== '';

    setIsCourse(courseValid);
    setIsCourseType(courseTypeValid);
    setIsCourseTime(courseTimeValid);

    return courseValid && courseTypeValid && courseTimeValid;
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true)

    if (validatePersonalDetails() && validateCourseDetails()) {

      const formData = {
        firstName,
        lastName,
        mobileNumber,
        email,
        address1,
        address2,
        pincode,
        course,
        courseType,
        courseTime,
        country: countryValue ? countryValue.label : '',
      };

      fetch(`${process.env.REACT_APP_API_URL}/api/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then(response => {
          if (response.ok) {
            setShowThankYou(true);
            // Reset form fields after successful submission
            setFirstName('');
            setLastName('');
            setEmail('');
            setDeclared(false);
            setTerms(false);
            setCourse('');
            setCourseType('');
            setCourseTime('');
            setMobileNumber('');
            setAddress1('')
            setAddress2('')
            setPincode('')
            setCountryValue(null);
          } else {
            console.error('Failed to submit form');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        })
        .finally(()=>{
          setIsSubmitting(false)
        });
    }
  }

  function handleContinue() {
    if (activeButton === 'personal') {
      if (validatePersonalDetails()) {
        setActiveButton('course');
      }
    } else if (activeButton === 'course') {
      if (validateCourseDetails()) {
        setActiveButton('payment');
      }
    }
  }

  function handleButtonClick(button) {
    if (button === 'personal' && showThankYou) {
      setShowThankYou(false); // Hide thank you message
    }
    setActiveButton(button);
  }

  return (
    <div className="Registerpage">
      <Helmet>
        <title>Enroll Now | Best German Language School in India</title>
        <meta name="description" content="Register for German language courses at the No. 1 German language school in India. Limited seats available! Start your journey to fluency today."/>
        <meta name="keywords" content="enroll in German courses, register for German classes, German language registration, best German language school, language enrollment India"/>
        <link rel="canonical" href="https://www.ourgermanschool.de/register" />
      </Helmet>
      <div className="backgroundPicture">
        {/* Background image will be applied through CSS */}
      </div>

      <div className="enrollText">
        <h2>Enroll with us now</h2>
      </div>

      <div className="formContainer">
        <div className="form-buttons">
          <button
            className={`button ${activeButton === 'personal' ? 'active' : ''}`}
            onClick={() => handleButtonClick('personal')}
            disabled={activeButton !== 'personal' && !showThankYou}
          >
            Personal Details
          </button>
          <button
            className={`button ${activeButton === 'course' ? 'active' : ''}`}
            onClick={() => handleButtonClick('course')}
            disabled={activeButton !== 'course' && !showThankYou}
          >
            Course Details
          </button>
          <button
            className={`button ${activeButton === 'payment' ? 'active' : ''}`}
            onClick={() => handleButtonClick('payment')}
            disabled={activeButton !== 'payment' && !showThankYou}
          >
            Payment
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          {showThankYou ? (
            <div className="thank-you-message">
              <h3>Thank You!</h3>
              <p>Your registration has been submitted successfully. We will get back to you shortly.</p>
            </div>
          ) : (
            <>
              {activeButton === "personal" && (
                <div>
                  <select className={`form-select ${isSelectOption ? '' : 'inValid'}`} onChange={e => setSelectOption(e.target.value)} aria-label="Select Option">
                    <option defaultValue>Select Option</option>
                    <option value="New student">New Student</option>
                  </select>
                  <div className="personalDetails">
                    <div className="col">
                      <input
                        type="text"
                        className={`form-control ${isFirstName ? '' : 'inValid'}`}
                        onChange={e => setFirstName(e.target.value)}
                        value={firstName}
                        placeholder="First name"
                        aria-label="First name"
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className={`form-control ${isLastName ? '' : 'inValid'}`}
                        onChange={e => setLastName(e.target.value)}
                        value={lastName}
                        placeholder="Last name"
                        aria-label="Last name"
                        required
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className={`form-control ${isMobileNumber ? '' : 'inValid'}`}
                        onChange={e => setMobileNumber(e.target.value)}
                        value={mobileNumber}
                        placeholder="Mobile Number"
                        aria-label="Mobile"
                        required
                      />
                    </div>
                  </div>

                  <div className="contactDetails">
                    <div className="col">
                      <input
                        type="email"
                        className={`form-control ${isEmail ? '' : 'inValid'}`}
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        placeholder="Email"
                        aria-label="Email"
                        required
                      />
                    </div>
                  </div>

                  {selectOption === "New student" && (
                    <div className="details">
                      <div className="row g-3">
                        <div className="col">
                          <input
                            type="text"
                            className={`form-control ${isAddress1 ? '' : 'inValid'}`}
                            onChange={e => setAddress1(e.target.value)}
                            value={address1}
                            placeholder="Address Line1"
                            aria-label="Address Line1"
                          />
                        </div>
                      </div>
                      <div className="row g-3">
                        <div className="col">
                          <input
                            type="text"
                            className={`form-control ${isAddress2 ? '' : 'inValid'}`}
                            onChange={e => setAddress2(e.target.value)}
                            placeholder="Address Line2"
                            aria-label="Address Line2"
                          />
                        </div>
                      </div>

                      <div className="detailsCity">
                        <div className="col">
                          <input
                            type="text"
                            className={`form-control ${isCity ? '' : 'inValid'}`}
                            onChange={e => setCity(e.target.value)}
                            placeholder="City"
                            aria-label="City"
                          />
                        </div>
                        <div className="col">
                          <Select
                            className="countryDrop"
                            onChange={value => setCountryValue(value)}
                            aria-label="Select Country"
                            options={countryOptions}
                            value={countryValue}
                            placeholder="Select your Country"
                          />
                        </div>
                        <div className="col">
                          <input
                            type="text"
                            className={`form-control ${isPincode? '' : 'inValid'}`}
                            onChange={e => setPincode(e.target.value)}
                            placeholder="Pincode"
                            aria-label="Pincode"
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="termsnConditions">
                    <div className="form-check">
                      <input
                        className={`form-check-input ${isDeclared ? '' : 'inValid'}`}
                        onChange={e => setDeclared(e.target.checked)}
                        type="checkbox"
                        checked={declared}
                        id="flexCheckDefault1"
                        required
                      />
                      <label className="form-check-label" htmlFor="flexCheckDefault1">
                        I hereby declare that the information furnished above is true, complete, and correct.
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className={`form-check-input ${isTerms ? '' : 'inValid'}`}
                        onChange={e => setTerms(e.target.checked)}
                        type="checkbox"
                        checked={terms}
                        id="flexCheckDefault2"
                        required
                      />
                      <label className="form-check-label" htmlFor="flexCheckDefault2">
                        I agree to the terms and conditions of DE German School LLP
                      </label>
                    </div>
                  </div>
                  <div className="continueButton">
                    <button className="btn btn-dark continueButton" type="button" onClick={handleContinue}>
                      Continue
                    </button>
                  </div>
                </div>
              )}

              {activeButton === "course" && (
                <div>
                  <div className="courseDetails">
                    <select
                      className={`form-select ${isCourse ? '' : 'inValid'}`}
                      onChange={e => setCourse(e.target.value)}
                      value={course}
                      aria-label="Default select example"
                    >
                      <option defaultValue>Select your desired course</option>
                      <option value="A1">A1</option>
                      <option value="A2">A2</option>
                      <option value="B1">B1</option>
                      <option value="B2">B2</option>
                      <option value="C1" disabled>C1 [ Currently unavailable ]</option>
                      <option value="C2" disabled>C2 [ Currently unavailable ]</option>
                      <option value="Grammar Course A1">Grammar A1</option>
                      <option value="Grammar Course A2">Grammar A2</option>
                      <option value="Grammar Course B1">Grammar B1</option>
                      <option value="Grammar Course B2">Grammar B2</option>
                      <option value="Exam Preparation A1">Exam Preparation A1</option>
                      <option value="Exam Preparation A2">Exam Preparation A2</option>
                      <option value="Exam Preparation B1">Exam Preparation B1</option>
                      <option value="Exam Preparation B2">Exam Preparation B2</option>

                    </select>

                    <select
                      className={`form-select ${isCourseType ? '' : 'inValid'}`}
                      onChange={e => setCourseType(e.target.value)}
                      value={courseType}
                      aria-label="Default select example"
                    >
                      <option defaultValue>Course Type</option>
                      <option value="Weekday">Weekday</option>
                      <option value="Weekend">Weekend</option>
                      <option value="Intensive">Intensive</option>
                    </select>

                    <select
                      className={`form-select ${isCourseTime ? '' : 'inValid'}`}
                      onChange={e => setCourseTime(e.target.value)}
                      value={courseTime}
                      aria-label="Default select example"
                    >
                      <option defaultValue>Select Time</option>
                      <option value="IST">IST</option>
                      <option value="CEST">CEST</option>
                    </select>
                  </div>
                  <div className="continueButton">
                    <button className="btn btn-dark continueButton" type="button" onClick={handleContinue}>
                      Continue
                    </button>
                  </div>
                </div>
              )}

              {activeButton === "payment" && (
                <div>
                  <h3>Payment Details Form</h3>
                  <div>
                    <h4>We will contact you, Please submit the form.</h4>
                  </div>
                  <div className="continueButton">
                    <button className="btn btn-dark continueButton" type="submit" disabled={isSubmitting}>
                      {isSubmitting?"Submitting...":"Submit"}
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </form>
      </div>
    </div>
  );
}

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import logo from "./Ourgermanlogo.png";
import { NavLink } from "react-router-dom";
import "./css/header.css";

const handleNavLinkClick = () => {
  const navbarCollapse = document.getElementById("navbarNav");
  if (navbarCollapse) {
    navbarCollapse.classList.remove("show");
  }
};

export default function Header() {
  return (
    <nav className="navbar navbar-expand-lg fixed-top headerNavBar">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={logo} alt="Logo" width="190" height="80" className="d-inline-block align-text-top" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`} to="/" onClick={handleNavLinkClick}>Home</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`} to="/aboutus" onClick={handleNavLinkClick}>About Us</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`} to="/courses" onClick={handleNavLinkClick}>Courses</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`} to="/ausbildung" onClick={handleNavLinkClick}>Ausbildung</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`} to='/register' onClick={handleNavLinkClick}>Register for Course</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`} to="/translation" onClick={handleNavLinkClick}>Certified Translations</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className={({ isActive }) => `nav-link ${isActive ? "active" : ""}`} to="/contact" onClick={handleNavLinkClick}>Contact Us</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

import React, { useState } from "react";
import "./testimonials.css";
import st1 from "./st1.webp";
import st2 from "./st2.webp";
import st3 from "./st3.webp";
import st4 from "./st4.webp";




const testimonials = [
  {
    id: 1,
    name: "Asha Varghese",
    image: st1,
    feedback:
      "This is Asha Varghese, I am a Masters student at the University of Bamberg. I recently moved to Germany and was searching for an online German class that fits my schedule with Classes and work. I came across Our German School online and quickly got in touch with them. They asked for my preference of timing and started off soon. I got personal attention throughout my course and was able to clarify all the queries as and when it arised. Dr. Prerana was my tutor and she was so proficient in German that she was able to guide me through the basics really well and set my foundation right. I completed A1 level and I have plans to continue with them. I would totally recommend Our German School and particularly Dr. Prerana.",
  },
  {
    id: 2,
    name: "Adarsh Mehtre",
    image: st2,
    feedback:
      "I took the German A1 and A2 courses, and they were very helpful. The teaching methods were practical and interactive, with a good mix of speaking, listening, reading, and writing exercises. The course materials were clear and well-structured, making it easy to follow and understand.The instructors were supportive, always available to clarify doubts and provide extra resources when needed. Thanks to these courses, I now feel much more confident in my German skills, and I would definitely recommend them to anyone starting out with the language.",
  },
  {
    id: 3,
    name: "Bharadwaj",
    image: st3,
    feedback:
    "I am really glad I got to learn German A1 and A2 from you. Your teaching methods made understanding and applying concepts easy. Your support and approachability, especially when things were difficult, really helped build my confidence. The homework assignments also reinforced what we learned and made German easier to grasp.Thank you for all the classes. It has made a big difference in my learning.Looking forward to many more classes with you."
  },
  {
    id:4,
    name:"Jishnu",
    image: st4,
    feedback:
    "I recently took a German language course, and I really enjoyed it! The lessons were well-structured and easy to follow, even for a beginner like me. The teacher was super patient and explained things in a way that made learning fun. I especially liked the interactive parts like practicing conversations and playing games,it helped me feel more confident using the language."
  }
  
];

const CarouselTestimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [expandedCards, setExpandedCards] = useState({}); // Track expanded cards

  const toggleReadMore = (id) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleCardClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="carousel-testimonial-container">
      <div className="carousel-testimonial-wrapper">
        {testimonials.slice(currentIndex, currentIndex + 1).map((testimonial) => (
          <div
            key={testimonial.id}
            className="carousel-testimonial-card animated-card"
          >
            <div className="carousel-testimonial-image-container">
              <img
                src={testimonial.image}
                alt={testimonial.name}
                className="carousel-testimonial-image"
              />
            </div>
            <div className="carousel-testimonial-content">
              <h3 className="carousel-testimonial-name">{testimonial.name}</h3>
              <div className="carousel-testimonial-feedback-container">
                <p className="carousel-testimonial-feedback">
                  {expandedCards[testimonial.id]
                    ? testimonial.feedback
                    : `${testimonial.feedback.substring(0, 200)}...`}
                </p>
                {testimonial.feedback.length > 200 && (
                  <button
                    className="carousel-testimonial-readmore"
                    onClick={() => toggleReadMore(testimonial.id)}
                  >
                    {expandedCards[testimonial.id] ? "Read Less" : "Read More"}
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Circle indicators */}
      <div className="carousel-indicators">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`carousel-indicator ${currentIndex === index ? "active" : ""}`}
            onClick={() => handleCardClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default CarouselTestimonials;
